import styles from './Navbar.module.css';

import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faX } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
    const [showNav, setShowNav] = useState(false);
    const toggleNav = () => {
        setShowNav(!showNav);
    }
    const closeNav = () => {
        setShowNav(false);
    }
    return (
        <nav className={styles.navbar}>
            <div className={styles['navbar-container']}>
                <img className={styles.logo} src={'../../' + process.env.PUBLIC_URL + '/logo.png'} alt='Nathan Yu Logo' />
                <div className={styles['menu-icon']} onClick={toggleNav}>
                    {showNav ? <FontAwesomeIcon icon={faX} /> : <FontAwesomeIcon icon={faBars} />}
                </div>
                <div className={`${showNav && styles.active} ${styles['nav-links']}`}>
                    <li>
                        <Link onClick={closeNav} to="home" spy={true} smooth={true} offset={-60} duration={500}>
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link onClick={closeNav} to="education" spy={true} smooth={true} offset={-60} duration={500}>
                            Education
                        </Link>
                    </li>
                    <li>
                        <Link onClick={closeNav} to="experience" spy={true} smooth={true} offset={-60} duration={500}>
                            Experience
                        </Link>
                    </li>
                    <li >
                        <Link onClick={closeNav} to="projects" spy={true} smooth={true} offset={-60} duration={500}>
                            Projects
                        </Link>
                    </li>
                    <li>
                        <Link onClick={closeNav} to="skills" spy={true} smooth={true} offset={-60} duration={500}>
                            Skills
                        </Link>
                    </li>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;