const skillData = [
    {
        label: 'Java',
        cssName: 'java',
        iconSrc: 'devicon-java-plain',
    },
    {
        label: 'Python',
        cssName: 'python',
        iconSrc: 'devicon-python-plain',
    },
    {
        label: 'C',
        cssName: 'c',
        iconSrc: 'devicon-c-plain',
    },
    {
        label: 'Go',
        cssName: 'go',
        iconSrc: 'devicon-go-plain',
    },
    {
        label: 'SQL',
        cssName: 'sql',
        iconSrc: 'fa fa-database',
    },
    {
        label: 'JavaScript',
        cssName: 'javascript',
        iconSrc: 'devicon-javascript-plain',
    },
    {
        label: 'Git',
        cssName: 'git',
        iconSrc: 'devicon-git-plain',
    },
    {
        label: 'RESTful APIs',
        cssName: 'restful-apis',
        iconSrc: 'fa fa-gear',
    },
    {
        label: 'MongoDB',
        cssName: 'mongodb',
        iconSrc: 'devicon-mongodb-plain',
    },
    {
        label: 'Spring Boot',
        cssName: 'spring-boot',
        iconSrc: 'devicon-spring-plain',
    },
    {
        label: 'React JS',
        cssName: 'react-js',
        iconSrc: 'devicon-react-original',
    },
    {
        label: 'React Native',
        cssName: 'react-native',
        iconSrc: 'devicon-react-original',
    },
    {
        label: 'Swift',
        cssName: 'swift',
        iconSrc: 'devicon-swift-plain',
    },

    {
        label: 'Express JS',
        cssName: 'express-js',
        iconSrc: 'devicon-express-original',
    },
    {
        label: 'Node JS',
        cssName: 'node-js',
        iconSrc: 'devicon-nodejs-plain',
    },
    {
        label: 'HTML',
        cssName: 'html',
        iconSrc: 'devicon-html5-plain',
    },
    {
        label: 'CSS',
        cssName: 'css',
        iconSrc: 'devicon-css3-plain',
    },
    {
        label: 'Pandas',
        cssName: 'pandas',
        iconSrc: 'devicon-pandas-original',
    },
    {
        label: 'R',
        cssName: 'r',
        iconSrc: 'devicon-r-original',
    },
    {
        label: 'NumPy',
        cssName: 'numpy',
        iconSrc: 'devicon-numpy-original',
    },
    {
        label: 'AWS Cloud Practitioner',
        cssName: 'aws',
        iconSrc: 'devicon-amazonwebservices-original',
    },
];

export default skillData;