import styles from './ProjectCard.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ExperienceCard = ({ imgLink, projectName, skillList, descriptionText, links }) => {
    return (
        <div className={styles['project-card-container']}>
            <img className={styles['img-link']} src={imgLink} alt='' />
            <div className={styles['project-detail-container']}>
                <p className={styles['project-name']}>{projectName}</p>
                <ul className={styles['links-list']}>
                    {links.map((s) => (
                        <li key={s.label}>
                            <a className={styles[s.label + '-icon']} href={s.link}>
                                <FontAwesomeIcon icon={s.icon} />
                            </a>
                        </li>
                    ))}
                </ul>
                <p className={styles[`description-text`]}>{descriptionText}</p>
                <ul className={styles['skill-list']}>
                    {skillList.map((s) => (
                        <li key={s.label}>{s}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default ExperienceCard;