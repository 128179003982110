import ExperienceCard from '../../components/ExperienceCard/ExperienceCard';
import styles from './Experience.module.css';

const Experience = () => {
    return (
        <section id='experience'>
            <div className='section-container-alt'>
                <h2 className='section-title'>Experience</h2>
                <div className={styles['card-container']}>
                    <ExperienceCard
                        logoLink={'../../' + process.env.PUBLIC_URL + '/bandwidth_logo.png'}
                        roleName='Software Engineering Intern'
                        dateString='May 2023 – Present'
                        descriptionList={[
                            'TBD :)']}
                    />
                    <ExperienceCard
                        logoLink={'../../' + process.env.PUBLIC_URL + '/duke_university_wordmark.png'}
                        roleName='CS 330 TA'
                        dateString='January 2023 – May 2023'
                        descriptionList={[
                            'Held office hours with queues up to 30 students, guiding students through proof and design of advanced algorithms', 'Graded assignments and exams']}
                    />
                    <ExperienceCard
                        logoLink={'../../' + process.env.PUBLIC_URL + '/PFG_logo.png'}
                        roleName='Software Engineering Intern'
                        dateString='May 2022 – August 2022'
                        descriptionList={[
                            'Developed an investment rate comparison feature in Java Spring Boot as a part of a rate processing microservice that ensures the company does not lose money through incorrectly submitted rates',
                            'Implemented a REST API for comparison report generation and distribution using Java which automatically compares rates then emails rate comparison results to business analysts',
                            'Built out a jar to contain DB2 SQL database queries that enable insertion and loading of financial data for 8 different types of contracts',
                            'Worked in an Agile scrum team and followed CI/CD practice, using Postman, unit tests, and WebSphere logging for continuous testing',
                            'Received 2nd place recognition for presentation of mainframe modernization plan to over 60 department members including executives']}
                    />
                    <ExperienceCard
                        logoLink={'../../' + process.env.PUBLIC_URL + '/mgel_logo.png'}
                        roleName='Data Science Intern'
                        dateString='May 2021 – January 2022'
                        descriptionList={[
                            'Enhanced the accuracy of automated whale call detector by verifying calls for 400+ hours of audio data in PamGuard software',
                            'Created confusion matrices and bar charts in R comparing the performance of 2 data analysts and an automated detector to discern differences in performance, efficiency, and accuracy when identifying whale calls']}
                    />
                </div>
            </div>
        </section>
    );
}

export default Experience;