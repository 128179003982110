/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.2 public/tuna_fish/scene.gltf -r public
Author: GoldenZtuff (https://sketchfab.com/dhjwdwd)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/tuna-fish-642c6515d893474a8fc8f129491efcac
Title: Tuna Fish
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
// import { useFrame } from '@react-three/fiber'

export function Tuna(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/tuna_fish/scene.gltf')
  const { actions } = useAnimations(animations, group);

  useEffect(() => {
    actions.Swim.play();
  }, [actions]);

  // let x_vel = 0;
  // let z_vel = 0;
  // let x_acc = -0.001;
  // let z_acc = -0.001;
  // useFrame(({ clock }) => {
  //   group.current.position.x += 1;
  //   console.log(clock.getElapsedTime());
  // })

  return (
    <group ref={group} {...props} dispose={null} >
      <group name="Sketchfab_Scene">
        <group name="Sketchfab_model" rotation={[-Math.PI / 2, 0, 0]} scale={0.61}>
          <group name="403fc3b9fb40406aa1459fb8a2ac9cd8fbx" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
            <group name="Object_2">
              <group name="RootNode">
                <group name="FishC" rotation={[-Math.PI / 2, 0, 0]} scale={100} />
                <group name="EyesC" rotation={[-Math.PI / 2, 0, 0]} scale={100} />
                <group name="ExportRIG" rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <group name="Object_7">
                    <primitive object={nodes._rootJoint} />
                    <group name="Object_9" rotation={[-Math.PI / 2, 0, 0]} scale={100} />
                    <group name="Object_11" rotation={[-Math.PI / 2, 0, 0]} scale={100} />
                    <group name="Object_110" rotation={[-Math.PI / 2, 0, 0]} scale={100} />
                    <skinnedMesh name="Object_10" geometry={nodes.Object_10.geometry} material={materials.Material} skeleton={nodes.Object_10.skeleton} />
                    <skinnedMesh name="Object_12" geometry={nodes.Object_12.geometry} material={materials['Material.003']} skeleton={nodes.Object_12.skeleton} />
                    <skinnedMesh name="Object_111" geometry={nodes.Object_111.geometry} material={materials['Material.004']} skeleton={nodes.Object_111.skeleton} />
                  </group>
                </group>
                <group name="CorneaC" rotation={[-Math.PI / 2, 0, 0]} scale={100} />
              </group>
            </group>
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/tuna_fish/scene.gltf')
