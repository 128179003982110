import styles from './ExperienceCard.module.css';

const ExperienceCard = ({ logoLink, roleName, dateString, descriptionList }) => {
    return (
        <div className={styles['experience-card-container']}>
            <img className={styles['logo']} src={logoLink} alt='' />
            <p className={styles['role-name']}>{roleName}</p>
            <p className={styles['date-text']}>{dateString}</p>
            <ul className={styles['description-list']}>
                {descriptionList.map((s) => (
                    <li key={s.label}>{s}</li>
                ))}
            </ul>


        </div>
    );
}

export default ExperienceCard;