import styles from './Home.module.css';
import data from '../../data/socialdata';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Home = () => {
    return (
        <section id='home' className={styles.home}>
            <div className={styles['home-container']}>
                <div className={styles['intro-content']}>
                    <p className={styles['name-text']}>Nathan Yu</p>
                    <ul className={styles['social-icons']}>
                        {data.map((s) => (
                            <li key={s.label}>
                                <a className={styles[s.label + '-icon']} href={s.link}>
                                    <FontAwesomeIcon icon={s.icon} />
                                </a>
                                <p className={styles[s.label + '-text']}>{s.label}</p>
                            </li>
                        ))}
                    </ul>
                    <p className={styles.blurb}>Hello! I'm a rising Senior at Duke University pursuing a degree in Computer Science. I have a passion for learning and problem solving through computer science and I'm looking to pursue a career in software development.</p>
                    <div className={styles['email-text']}>
                        <p>If you'd like to contact me, my email is</p>
                        <a href='mailto:nathan.yu@duke.edu'>nathan.yu@duke.edu</a>
                    </div>
                </div>
                <img className={styles.portrait} src={'../../' + process.env.PUBLIC_URL + '/portrait_icon.png'} alt='profile-icon' />
            </div>
        </section >
    );
}

export default Home;