import './App.css';
import Navbar from './pages/Navbar/Navbar.js';
import Home from './pages/Home/Home.js';
import Education from './pages/Education/Education.js';
import Experience from './pages/Experience/Experience.js';
import Projects from './pages/Projects/Projects.js';
import Skills from './pages/Skills/Skills.js';
import Footer from './pages/Footer/Footer.js';

function App() {
  return (
    <div className='appContainer'>
      <Navbar />
      <Home />
      <Education />
      <Experience />
      <Projects />
      <Skills />
      <Footer />
    </div >
  );
}

export default App;
