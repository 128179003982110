import React, { useState } from 'react';

import styles from './Skills.module.css';
import skillData from '../../data/skilldata';

const Skills = () => {
    const [skillSection, setSkillSection] = useState('all');
    const toggleSkill = (section) => {
        setSkillSection(section);
    }
    return (
        <section id='skills'>
            <div className='section-container'>
                <h2 className='section-title'>Skills</h2>
                <div className={styles['skill-section-container']}>
                    <div className={styles['skill-button-container']}>
                        <button className={`${styles['skill-button']} ${skillSection === 'all' ? styles['pressed'] : styles['not-pressed']}`} onClick={() => {
                            toggleSkill('all');
                        }}>All</button>
                        <button className={`${styles['skill-button']} ${skillSection === 'languages' ? styles['pressed'] : styles['not-pressed']}`} onClick={() => {
                            toggleSkill('languages');
                        }}>Languages</button>
                        <button className={`${styles['skill-button']} ${skillSection === 'libraries-frameworks' ? styles['pressed'] : styles['not-pressed']}`} onClick={() => {
                            toggleSkill('libraries-frameworks');
                        }}>Libraries & Frameworks</button>
                        <button className={`${styles['skill-button']} ${skillSection === 'backend' ? styles['pressed'] : styles['not-pressed']}`} onClick={() => {
                            toggleSkill('backend');
                        }}>Backend</button>
                        <button className={`${styles['skill-button']} ${skillSection === 'frontend' ? styles['pressed'] : styles['not-pressed']}`} onClick={() => {
                            toggleSkill('frontend')
                        }}>Frontend</button>
                        <button className={`${styles['skill-button']} ${skillSection === 'data-science' ? styles['pressed'] : styles['not-pressed']}`} onClick={() => {
                            toggleSkill('data-science');
                        }}>Data Science</button>
                        <button className={`${styles['skill-button']} ${skillSection === 'mobile' ? styles['pressed'] : styles['not-pressed']}`} onClick={() => {
                            toggleSkill('mobile');
                        }}>Mobile</button>
                        <button className={`${styles['skill-button']} ${skillSection === 'certificates' ? styles['pressed'] : styles['not-pressed']}`} onClick={() => {
                            toggleSkill('certificates');
                        }}>Certificates</button>
                    </div>
                    <div className={styles['skill-icon-container']}>
                        {skillData.map((s) => (
                            <div key={s.label} className={`${styles['skill-card']} ${styles[s.cssName]} ${styles[skillSection]}`}>
                                <i className={s.iconSrc}></i>
                                <p className={styles['skill-text']}>{s.label}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Skills;
