import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope';
import { faFile } from '@fortawesome/free-regular-svg-icons/faFile';

const data = [
    {
        link: 'https://github.com/nyu12345',
        label: 'Github',
        icon: faGithub,
    },
    {
        link: 'https://www.linkedin.com/in/nathanyu-',
        label: 'LinkedIn',
        icon: faLinkedinIn,
    },
    {
        link: 'mailto:nathan.yu@duke.edu',
        label: 'Email',
        icon: faEnvelope,
    },
    {
        link: '../../' + process.env.PUBLIC_URL + '/resume.pdf',
        label: 'Resume',
        icon: faFile,
    },
];

export default data;