// import styles from './Projects.module.css'; all styling is in ProjectCard
import ProjectCard from '../../components/ProjectCard/ProjectCard';

import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';
import { faFolder } from '@fortawesome/free-regular-svg-icons/faFolder';
const Projects = () => {
    return (
        <section id='projects'>
            <div className='section-container-alt'>
                <h2 className='section-title'>Projects</h2>
                <ProjectCard
                    imgLink={'../../' + process.env.PUBLIC_URL + '/balance.png'}
                    projectName='Proof of Stake Blockchain Security Simulator'
                    skillList={['Go']}
                    descriptionText='A simulator in Go which uses socket connections and goroutines to simulate decentralized implementations of proof of stake blockchains. Can choose between standard proof of stake, reputation based, and slashing which are loosley based on Casper. Can simulate network partition and long range attacks on various blockchains to see how many malicious blocks are proposed and how long it takes to validate transactions.'
                    links={[{
                        label: 'github',
                        link: 'https://github.com/nyu12345/PoS-Security-Simulator',
                        icon: faGithub
                    }]}
                />
                <ProjectCard
                    imgLink={'../../' + process.env.PUBLIC_URL + '/music-map.png'}
                    projectName='Music Map: Roadtrip Music Tracker'
                    skillList={['React Native', 'JavaScript', 'MongoDB', 'Express.js', 'Node.js', 'Spotify API']}
                    descriptionText='MusicMap is a a mobile app that documents the songs played during road trips, allowing users to not only revisit a catalog of all the songs played during the trip, but also the specific locations associated with each song.'
                    links={[{
                        label: 'Github',
                        link: 'https://github.com/nyu12345/MusicMap',
                        icon: faGithub
                    },]}
                />
                <ProjectCard
                    imgLink={'../../' + process.env.PUBLIC_URL + '/medicar.png'}
                    projectName='Medic.AR: First Aid AR Modeling App'
                    skillList={['Swift', 'Firebase', 'EchoAR']}
                    descriptionText='Medic.AR is a hackathon mobile app that seeks to allow anyone to act properly in first aid situations through the guidance of AR models and tutorials. Best use of echoAR award at Sigma Hacks Hackathon'
                    links={[{
                        label: 'github',
                        link: 'https://github.com/jjang32/FirstAid',
                        icon: faGithub
                    },
                    {
                        label: 'website',
                        link: 'https://devpost.com/software/medic-io',
                        icon: faFolder
                    },]}
                />
                <ProjectCard
                    imgLink={'../../' + process.env.PUBLIC_URL + '/ecoeats.png'}
                    projectName='EcoEats: Meal Carbon Tracking App'
                    skillList={['Java', 'Android Studio', 'Firebase']}
                    descriptionText='Ecoeats is a hackathon android app that promotes sustainability through quickly calculating carbon costs of meals through your camera. It also offers sustainable recommendations and allows seeing carbon statistics for your friends too. Won Wolfram Alpha award at Hack Duke.'
                    links={[{
                        label: 'github',
                        link: 'https://github.com/BenCrespoDuke/HackDuke',
                        icon: faGithub
                    },
                    {
                        label: 'website',
                        link: 'https://devpost.com/software/ecoeats-8vb6tg#updates',
                        icon: faFolder
                    },]}
                />
                <ProjectCard
                    imgLink={'../../' + process.env.PUBLIC_URL + '/streamindex.png'}
                    projectName='Stream Index Calculator'
                    skillList={['Java', 'Java Swing GUI']}
                    descriptionText='This Stream Index Calculator is a java application that is designed for an environmental science class to quickly study and record macroinvertebrates and chemical levels in a stream to determine stream health/water quality. Allows user to upload map images with assignable markers to denote locations of interest. Digitilizes traditional paper indexing charts for calculating stream health'
                    links={[{
                        label: 'github',
                        link: 'https://github.com/nyu12345/Stream_index_calculator',
                        icon: faGithub
                    }]}
                />

            </div>
        </section>
    );
}

export default Projects;
