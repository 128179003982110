import styles from './Education.module.css';

import useCollapse from '@gaearon/react-collapsed';

const Education = () => {
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
    return (
        <section id='education'>
            <div className={`section-container ${styles['education-container']}`}>
                <div className={styles['education-background']}></div>
                <h2 className='section-title'>Education</h2>
                <div className={styles['school-container']}>
                    <img className={styles['duke-name']} src={'../../' + process.env.PUBLIC_URL + '/duke_university_wordmark.png'} alt='' />
                    <p className={styles['degree-name']}>Bachelor of Science in Computer Science</p>
                    <p className={`${styles['subtext']} ${styles['date-text']}`}>August 2020 - May 2024</p>
                    <p className={`${styles['subtext']} ${styles['gpa-text']}`}>GPA: 3.9/4.0</p>

                </div>
                <div className={styles['courses-container']}>
                    <button className={styles['courses-list-title']}
                        {...getToggleProps()}>{isExpanded ? 'Hide' : 'View'} Courses</button>
                    <div {...getCollapseProps()} className={styles['list-container']}>
                        <ul className={styles['courses-list']}>
                            <li>Distributed Systems</li>
                            <li>Design & Analysis of Algorithms</li>
                            <li>Data Structures & Algorithms</li>
                            <li>Database Systems</li>
                            <li>Operating Systems</li>
                            <li>Network Architecture</li>
                            <li>Elements of Machine Learning</li>
                            <li>Mobile Applications</li>
                            <li>Computer Architecture</li>
                            <li>Discrete Math For Computer Science</li>
                            <li>Graph-Matrix Analysis</li>
                            <li>Linear Algebra</li>
                            <li>Computer Vision</li>
                            <li>Multivariable Calculus</li>
                            <li>Statistical Regression</li>
                            <li>Introduction to Data Science</li>

                        </ul>
                    </div>
                </div>
            </div >
        </section >
    );
}

export default Education;