/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.2 public/sardine_swim/scene.gltf -r public
Author: Drake (https://sketchfab.com/dlp14c)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/sardine-swim-1c006b4427184eaba81d95d55ccf343b
Title: Sardine Swim
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'

export function Sardine(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/sardine_swim/scene.gltf')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    Object.values(actions)[0].play();
  }, [actions]);

  let x_vel = 0;
  let z_vel = 0;
  let x_acc = -0.001;
  let z_acc = -0.001;
  useFrame(({ clock }) => {
    x_vel += x_acc;
    z_vel += z_acc;
    if (10 - Math.abs(clock.getElapsedTime() % 10) < 0.02) {
      console.log(x_vel);
      x_acc *= -1;
      z_acc *= -1;
    }
    group.current.position.x += x_vel;
    group.current.position.z += z_vel;
  })
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Sketchfab_Scene">
        <group name="Sketchfab_model" rotation={[-Math.PI / 2, 0, 0]} scale={2.76}>
          <group name="39220e7b64a44e1cb1eb342603bb7ed6fbx" rotation={[Math.PI / 2, 0, 0]}>
            <group name="Object_2">
              <group name="RootNode">
                <group name="Armature" position={[-0.94, 1.11, -71.74]} rotation={[Math.PI / 2, 0, 0]} scale={24.52}>
                  <group name="Object_5">
                    <primitive object={nodes._rootJoint} />
                    <group name="Object_13" rotation={[-Math.PI / 2, 0, 0]} scale={[23.06, 15.7, 16.09]} />
                    <skinnedMesh name="Object_14" geometry={nodes.Object_14.geometry} material={materials.fish} skeleton={nodes.Object_14.skeleton} />
                  </group>
                </group>
                <group name="Sphere" rotation={[-Math.PI / 2, 0, 0]} scale={[23.06, 15.7, 16.09]} />
              </group>
            </group>
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/sardine_swim/scene.gltf')
