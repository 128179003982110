// import React, { useEffect, useRef } from 'react';
import { Tuna } from '../../components/Tuna';
import { Sardine } from '../../components/Sardine';
// import { useFrame, useThree } from '@react-three/fiber';
import styles from './Footer.module.css';

import { Canvas } from '@react-three/fiber';

const Footer = () => {
    // const Camera = (props) => {
    //     const ref = useRef();
    //     const set = useThree((state) => state.set);
    //     useEffect(() => void set({ camera: ref.current }), [set]);
    //     useFrame(() => ref.current.updateMatrixWorld());
    //     return <perspectiveCamera ref={ref} {...props} />;
    // };

    // const tuna = useRef();
    // const [tunaPosition, setTunaPosition] = useState({ x: 2, y: 2, z: 0 });

    return (
        <section id='footer'>
            <h2 className='section-title'>Work in Progress...</h2>
            <div className={styles['footer-container']}>
                <Canvas camera={{ fov: 75, position: [0, 0, 0], rotation: [0, 0, 0] }} >
                    <ambientLight />
                    <pointLight position={[5, 5, 5]} intensity={1} />
                    <pointLight position={[-3, -3, 2]} intensity={1} />
                    <Tuna position={[0, 0, -5]} rotation={[0, 1.57, 0]} scale={2} />
                    <Sardine position={[4, -0.2, 0]} rotation={[0, -1.57, 0]} scale={0.002} />

                </Canvas>
            </div>
        </section >
    );
}

export default Footer;